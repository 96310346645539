<template>
  <div class="table-responsive">
    <table
      class="table table-striped table-bordered table-sm table-responsive-sm mt-4"
    >
      <thead>
        <tr>
          <th>No</th>
          <th>Payment Gateway</th>
          <th>Total</th>
          <th>Price IDR</th>
          <th>Price USD</th>
          <th>Price Point</th>
          <th>Gross Profit Margin</th>
          <th>Gateway Fee</th>
          <th> Gateway Cost</th>
          <th> Balance</th>
          <th>Publisher net Share </th>
          <th>Fee Aplication</th>
        </tr>
      </thead>
      <tbody v-if="!isLoading">
        <template v-for="(item, index) in items">
          <tr :key="item.id">
            <td>{{ ++index }}</td>
            <td style="width:25%">{{ Payment(item.paymentgateway_id) }}</td>
            <td>{{ item.total }}</td>
            <td v-if="item.currency_code == 'IDR'" class="text-right">
              {{ formatPrice(item.total_amount, item.currency_code) }}
            </td>
            <td v-else></td>
            <td v-if="item.currency_code == 'USD'" class="text-right" style="width:30%">
              {{ formatPrice(item.total_amount, item.currency_code) }}
            </td>
            <td v-else></td>
            <td v-if="item.currency_code == 'PTS'" class="text-right">
              {{ formatPrice(item.total_amount, item.currency_code) }}
            </td>
            <td v-else></td>
            <td class="text-right">{{ formatPrice(item.total_amount, item.currency_code) }}</td>
            <td class="text-right">
              {{ FormatFormula(PaymentCost(item.paymentgateway_id))}}
            </td>
            <td class="text-right">
              {{ formatPrice(CountAmountCost(item.total_amount,item.total,item.paymentgateway_id),item.currency_code)}}
            </td>
            <td class="text-right">
              {{ formatPrice(finalAmount(item.total_amount,CountAmountCost(item.total_amount,item.total,item.paymentgateway_id)),item.currency_code)}}
            </td>
            <td>
              {{ formatPrice(publisherNetshare(finalAmount(item.total_amount,CountAmountCost(item.total_amount,item.total,item.paymentgateway_id))),item.currency_code)}}
            </td>
            <td>
              {{ formatPrice(applicationsFee(finalAmount(item.total_amount,CountAmountCost(item.total_amount,item.total,item.paymentgateway_id))),item.currency_code)}}
            </td>
          </tr>
        </template>
      </tbody>
      <tbody v-else>
        <template>
          <tr>
            <td colspan="12">
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Loading...</strong>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="2" class="text-center">Total</td>
          <td>
            {{ formatPriceBasic(total_transactions) }}
          </td>
          <td class="text-right">
            {{ formatPrice(totalAmount, "IDR") }}
          </td>
          <td>
            {{ formatPrice(totalAmountD, "USD") }}
            ( {{ formatPrice(totalAmountD * curs, "IDR") }} )
          </td>
          <td class="text-right">
            {{ formatPrice(totalAmountPTS, "IDR") }}
          </td>
        </tr>
        <tr>
          <td colspan="6" class="text-center">Total</td>
          <td class="text-right">
            <strong>
              {{ formatPrice(SumAmount, "IDR") }}
            </strong>
          </td>
          <td></td>
          <td class="text-right">
            <strong>
              {{ formatPrice(totalGatewayCost, "IDR") }}
            </strong>
          </td>
          
          <td class="text-right">
            <strong>
              {{ formatPrice((SumAmount- totalGatewayCost), "IDR") }}
            </strong>
          </td>
          <td class="text-right">
            <strong>
              {{ formatPrice(((SumAmount- totalGatewayCost) * 0.7), "IDR") }}
            </strong>
          </td>
          <td class="text-right">
            <strong>
              {{ formatPrice(((SumAmount- totalGatewayCost) * 0.3), "IDR") }}
            </strong>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { mapState } from "vuex"
  export default {
    name: 'TableReport',
    props: {
      items: {
        type: Array,
        required: true
      },
      isLoading: {
        type: Boolean,
        required: true
      },
      totalAmount:{
        type: Number,
        required: true
      },
      totalAmountD:{
        type: Number,
        required: true
      },
      totalAmountPTS:{
        type: Number,
        required: true
      },
      totalGatewayCost:{
        type: Number,
        required: true
      },
      SumAmount :{
        type: Number,
        required: true
      },
      curs: {
        type: Number,
        required: true
      },
      total_transactions: {
        type: Number,
        required: true
      }
    },
    computed: {
    ...mapState({
      payment: (state) => state.sales.payments,
    })
  },
    methods: {
      formatPrice(value, currency) {
        if (value) {
          var formatter = null;
          if (currency == "USD") {
            formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: currency,
            });
          } else {
            formatter = new Intl.NumberFormat("en-ID", {
              style: "currency",
              currency: currency,
            });
          }

          return formatter.format(value);
        }

        return "0";
      },
      CountAmountCost(amount,count,gatewayid){
        let val = this.PaymentCost(gatewayid)
        if (parseFloat(val.value) > 0 && parseInt(val.fixed_amount) > 0) {
          return (amount * val.value) + val.fixed_amount
        } else if (val.value < 1 && val.fixed_amount > 0){
          return count * val.fixed_amount;
        } else if(val.value > 0 && val.fixed_amount < 1){
          return amount * val.value;
        }
      },
      finalAmount(amount, cost) {
        return parseFloat(amount) - parseFloat(cost);
      },
      publisherNetshare(price){
        let appfee =  price * 0.30;
        if (appfee) {
          return price -appfee
        }
      },
      applicationsFee(price){
        if (price) {
          return  price * 0.30;
        }
      },
      Payment(id) {
        let p = this.payment.find((e) => e.id == id);
        if (p) {
          return `${p.name} ( ${p.payment_group} )`;
        } else {
          return id;
        }
      },
      PaymentCost(id){
        let p = this.payment.find((e) => e.id == id);
        if (p) {
          let formula = p.formula
          let fixedFormula = parseInt(formula.fixed_amount) > 0 ?  parseInt(formula.fixed_amount) :0;
          let value = parseFloat(formula.percentage_amount) > 0 ? parseFloat(formula.percentage_amount): 0
          return {
            fixed_amount: fixedFormula,
            value: value ,
          };
        } else {
          return id;
        }
      },
       FormatFormula (data){
        if (data) {
          if(data.fixed_amount > 0 && data.value > 0){
            return `Rp ${data.value} + ${data.fixed_amount}`;
          } else if(data.fixed_amount > 0 && data.value < 1) {
            return `Rp ${data.fixed_amount}`
          } else {
            return `${data.value}`;
          }
        }
      },
      formatPriceBasic(value) {
        let val = (value / 1).toFixed(0).replace(".", ",");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },
    }
  }
</script>

<style lang="scss" scoped>

</style>