<template>
  <div class="row books">
    <div class="col-md-12 mt-2">
      <div class="col-md-4">
        
      </div>
      <div class="card p-2">
        <h4 class="h3 text-center">Sales Report</h4>
        <b-alert show>
          Laporan total penjualan dari semua offers. Laporan di group berdasarkan payment gateway. ( Exclude Payment Free and Gramedia.com )
        </b-alert>

        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              {{ errorMessage }}
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <div class="form-group row">
            <span class="col-sm-2">Select Range</span>
            <div class="col-md-8">
              <v-date-picker
                v-model="range"
                mode="date"
                :masks="masks"
                :max-date="new Date()"
                is-range
              >
                <template v-slot="{ inputValue, inputEvents, isDragging }">
                  <div class="rows">
                    <input
                      class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                      :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                      :value="inputValue.start"
                      v-on="inputEvents.start"
                      :max-date="new Date()"
                    />

                    <span class="p-2">to</span>

                    <input
                      class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                      :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                      :value="inputValue.end"
                      v-on="inputEvents.end"
                      :max-date="new Date()"
                    />
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="col-md-2">
              <button class="btn btn-block btn-primary" @click="actionSearch">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
          <div class="row justify-content-end">
            
            <div class="col-md-2 mb-1">
              <input
                type="text"
                v-model="curs"
                class="form-control"
                placeholder="14000"
              />
            </div>
            <div class="col-md-1 mb-1">
              <button
                class="btn btn-block btn-outline-danger"
                @click="actionCreateTablePDF"
              >
                <i class="fa fa-file-pdf-o"></i>
              </button>
            </div>
            <div class="col-md-1 mb-1">
              <button
                class="btn btn-block btn-outline-success"
                @click="actionDownloadRecap"
              >
                <i class="fa fa-file-excel-o" aria-hidden="true"></i>
              </button>
            </div>
            <div class="col-md-2">
              <label for="" class="col-sm-auto">Count </label>
              <strong class="col-sm-auto">
                {{ formatPriceBasic(totalRows) }}
              </strong>
            </div>
          </div>
          <div id="table" class="col-md-12">
            <TableReport :items="items" :isLoading="isLoading"
             :totalAmount="totalAmount" :totalAmountPTS="totalAmountPTS"
             :totalAmountD="totalAmountD" :SumAmount="SumAmount"
             :totalGatewayCost="totalGatewayCost" :totalRows="totalRows"
             :curs="curs" :total_transactions="total_transactions"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import PDFReports from "../../plugins/PDFReports"
import { mapActions,mapMutations, mapGetters ,mapState} from "vuex";
import Xlsreport from "../../plugins/Xlsreport"
// import DonutChart from "../../components/orders/DonutChart.vue"
import TableReport from "./tablereport.vue"
export default {
  name: "Salesreport",
  components: {
    // DonutChart,
    TableReport
  },
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      reports: [],
      totalAmount: 0,
      totalAmountD: 0,
      totalAmountPTS: 0,
      SumAmount: 0,
      totalGatewayCost:0,
      publishershare:0,
      applications_fee:0,
      curs: 14200,
      Dates: {
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
      },
      range: {
        start: moment().format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
      },
      masks: {
        input: "YYYY-MM-DD",
      },
      total_transactions:0,
      series: [{ data: [],name:"Transaction" }],
      options: {},
      seriesgroup: [],
      chartOptions:{}
    };
  },
  watch: {
    currentPage: function() {
      this.actionSearch();
    },
    items: function() {
      if (this.items && this.items.length > 0) {
        this.actionDestructor();
        this.getSumPayment()
        this.countTransactions(this.items)
        this.ChartTransactions()
        this.PaymentChart()
      }
    },
    curs: function() {
      if (this.curs) {
        this.actionDestructor();
        this.getSumPayment()
      }
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Sales Report By Payments | Content Management System Ebooks Gramedia.com';
      }
    },
  },
  computed: {
    ...mapGetters('sales',['items','isLoading','isError','payments','errorMessage']),
    ...mapState({
      payment: (state) => state.sales.payments,
    })
  },
  mounted(){
    this.setItems([])
    this.setError(false)
  },
  methods: {
    ...mapActions("sales", ["fetchReportByPayment"]),
    ...mapMutations("sales",["setItems","setError"]),
    countTransactions(items) {
      let count = 0;
      items.forEach((item) => {
        count += parseInt(item.total);
      });
      this.total_transactions = count;
    },
    PaymentChart(){
      let m =0
      let e =0;
      let itunes = 0
      let giap = 0
      let midtrans = this.payment.filter(p => p.payment_group=='midtrans')
      let e2pay = this.payment.filter(p => p.payment_group=='e2pay')
      this.items.map((item) => {
        if(item.paymentgateway_id == 1){
          itunes += parseInt(item.total)
        }else if(item.paymentgateway_id == 15){
          giap += parseInt(item.total)
        } else {
          let findmidtrans = midtrans.find(p => p.id == item.paymentgateway_id)
          let finde2pay = e2pay.find(p => p.id == item.paymentgateway_id)
          if(findmidtrans){
            m += parseInt(item.total)
          }else if (finde2pay) {
            e += parseInt(item.total)
          }
          
        }
      })
      
      this.seriesgroup =[m,e,itunes,giap]
      let labels = ["Midtrans","E2Pay","iTunes","Google Payment"]
      this.chartOptions = {
        labels: labels,
        colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
            '#f48024', '#69d2e7'
        ],
        legend: {
          display: false,
          position: 'bottom',
        },
        dataLabels: {
          enabled: true,
          position: 'bottom',
          formatter: function(val) {
            return parseFloat(val).toFixed(2) + "%";
          }
        },
        markers: {
          size: 10,
          hover: {
            size: 12,
          }
        },
        yaxis: {
          labels: {
            show: true,
            position: 'bottom',
            formatter: function(val) {
              return parseFloat(val);
            }
          }
        },
        xaxis: {
          labels: {
            show: true,
            position: 'bottom',
            formatter: function(val) {
              return parseFloat(val);
            }
          }
        },
      }
      
    },
    ChartTransactions(){
      let total = this.items.map((item) => parseInt(item.total))
      this.series[0].data = total.sort((a, b) => b - a)
      let labels = this.items.map((item) => this.Payment(item.paymentgateway_id))
      
      this.options=  {
        plotOptions: {
            bar: {
              barHeight: '100%',
              distributed: true,
              horizontal: true,
            },
            
          },
          colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
            '#f48024', '#69d2e7'
          ],
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '12px',
            },
            formatter: value => value
          },
          tooltip: {
            theme: "dark",
            dataLabels: true,
            y: {
              formatter: value => value
            }
          },
          title: {
            text: "Transaction " + this.total_transactions ,
            align: "left"
          },
          xaxis: {
            categories: labels,
            align: "left",
            labels: {
              show: false,
              formatter: value => value
            }
          },
          yaxis: {
            labels: {
              show: true,
            }
          },
      }
    },
    formatPrice(value, currency) {
      if (value) {
        var formatter = null;
        if (currency == "USD") {
          formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat("en-ID", {
            style: "currency",
            currency: currency,
          });
        }

        return formatter.format(value);
      }

      return "0";
    },
    formatDate(tgl) {
      //return moment(tgl).format("DD MMM YY hh:mm");
      return new Date(tgl).toLocaleString();
    },
    customLabel({ name }) {
      if (name) {
        return name;
      } else {
        return "Type Here";
      }
    },
    actionSearch() {
      let payload = {
        from: moment(this.range.start).format("YYYY-MM-DD"),
        to: moment(this.range.end).format("YYYY-MM-DD"),
        page: this.currentPage,
        limit: this.perPage,
      };

      try {
        this.fetchReportByPayment(payload);
      } catch (error) {
        this.isError = true;
        console.log(error);
      }
    },
    actionDestructor() {
      console.log("call ");
      let t = this.items.map((e) => {
        if (e.currency_code == "IDR") {
          return e.total_amount;
        }
        return 0;
      });
      let pts = this.items.map((e) => {
        if (e.currency_code == "PTS") {
          return e.total_amount;
        }
        return 0;
      });
      let td = this.items.map((e) => {
        if (e.currency_code == "USD") {
          return e.total_amount;
        }
        return 0;
      });

      this.totalRows = this.items.length;
      console.log(t);
      this.totalAmount = t.reduce((a, b) => parseInt(a) + parseInt(b));
      this.totalAmountD = td.reduce((a, b) => parseInt(a) + parseInt(b));
      this.totalAmountPTS = pts.reduce((a, b) => parseInt(a) + parseInt(b));
      
      let c = this.totalAmountD * this.curs;
      console.log(this.totalAmount);
      console.log(this.totalAmountPTS + this.totalAmount);
      this.SumAmount = this.totalAmountPTS + this.totalAmount + c;
      
    },
    getSumPayment(){
      let total =0
      this.items.forEach(element => {
        if (element.currency_code =="USD") {
          let d = this.CountAmountCost(element.total_amount,element.total,element.paymentgateway_id)
          total += (d*this.curs)
        } else {

          let c = this.CountAmountCost(element.total_amount,element.total,element.paymentgateway_id)
          total+= c
        }
      });
      this.totalGatewayCost = total
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    Payment(id) {
      let p = this.payment.find((e) => e.id == id);
      if (p) {
        return `${p.name} ( ${p.payment_group} )`;
      } else {
        return id;
      }
    },
    PaymentCost(id){
      let p = this.payment.find((e) => e.id == id);
      if (p) {
        let formula = p.formula
        let fixedFormula = parseInt(formula.fixed_amount) > 0 ?  parseInt(formula.fixed_amount) :0;
        let value = parseFloat(formula.percentage_amount) > 0 ? parseFloat(formula.percentage_amount): 0
        return {
          fixed_amount: fixedFormula,
          value: value ,
        };
      } else {
        return id;
      }
    },
    FormatFormula (data){
      if (data) {
        if(data.fixed_amount > 0 && data.value > 0){
          return `Rp ${data.value} + ${data.fixed_amount}`;
        } else if(data.fixed_amount > 0 && data.value < 1) {
          return `Rp ${data.fixed_amount}`
        } else {
          return `${data.value}`;
        }
      }
    },
    CountAmountCost(amount,count,gatewayid){
      let val = this.PaymentCost(gatewayid)
      if (parseFloat(val.value) > 0 && parseInt(val.fixed_amount) > 0) {
        return (amount * val.value) + val.fixed_amount
      } else if (val.value < 1 && val.fixed_amount > 0){
        return count * val.fixed_amount;
      } else if(val.value > 0 && val.fixed_amount < 1){
        return amount * val.value;
      }
    },
    finalAmount(amount, cost) {
      return parseFloat(amount) - parseFloat(cost);
    },
    publisherNetshare(price){
      let appfee =  price * 0.30;
      if (appfee) {
        return price -appfee
      }
    },
    applicationsFee(price){
      if (price) {
        return  price * 0.30;
      }
    },
    setTitleHead(){
       let head =["No","Payment Gateway","Total","Price IDR","Price USD","Price Point",
        "Gross Profit Margin","Gateway Fee","Gateway Cost","Balance"
      ]
      return head
    },
    setBodyData(){
      let datatable = []
      let n = 0;
      this.items.forEach((e) => {
        let amount_idr = this.formatPrice(
          parseInt(e.total_amount),
          e.currency_code
        );
        let amount_usd = this.formatPrice(e.total_amount, e.currency_code);
        let amount_pts = this.formatPrice(
          parseInt(e.total_amount),
          e.currency_code
        );
        let gateway = this.Payment(e.paymentgateway_id);
        
        let datain=[
          {content: ++n},
          {content: gateway},
          {content:e.total,styles: { halign: 'center' }},
          {content:e.currency_code=="IDR" ? amount_idr:0,styles: { halign: 'right' }},
          {content:e.currency_code=="USD" ? amount_usd:0,styles: { halign: 'right' }},
          {content:e.currency_code=="PTS" ? amount_pts:0,styles: { halign: 'right' }},
          {content:this.formatPrice(e.total_amount, e.currency_code),styles: { halign: 'right' }},
          {content:this.FormatFormula(this.PaymentCost(e.paymentgateway_id)),styles: { halign: 'right' }},
          {content:this.formatPrice(this.CountAmountCost(e.total_amount,e.total,e.paymentgateway_id),e.currency_code),styles: { halign: 'right' }},
          {content:this.formatPrice(this.finalAmount(e.total_amount,this.CountAmountCost(e.total_amount,e.total,e.paymentgateway_id)),e.currency_code),styles: { halign: 'right' }}
        ];
        datatable.push(datain)
      });
      return datatable
    },
    setFooter(){
      let datatable = []
      let span = [{
        content: 'Total',
        colSpan: 3,
        styles: { halign: 'center',fontStyle:'bold' }
      },
      
      { content: this.formatPrice(this.totalAmount,"IDR"),styles: { halign: 'right' }},
      { content: this.formatPrice(this.totalAmountD * this.curs, "IDR"),styles: { halign: 'right' }},
      { content: this.formatPrice(this.totalAmountPTS,"IDR"),styles: { halign: 'right' }}
      ]
      datatable.push(span)
      let span2 = [{
          content: 'Total',
          colSpan: 6,
          styles: { halign: 'center',fontStyle:'bold' }
        },
        
        { content: this.formatPrice(this.SumAmount, "IDR"),styles: { halign: 'right' }},
        {content:''},
        { content:this.formatPrice(this.totalGatewayCost, "IDR"),styles: { halign: 'right' }},
        { content: this.formatPrice((this.SumAmount- this.totalGatewayCost), "IDR"),styles: { halign: 'right',fontStyle:'bold' }}
      ]
      datatable.push(span2)
      return datatable
    },
    actionCreateTablePDF() {
      let payload = {
        from: this.range.start,
        to: this.range.end
      };
      let data = this.setBodyData()
      let footer = this.setFooter()
      let datatable = data.concat(footer)
      let report = new PDFReports(`By Payment Gateway`, payload,this.curs,`Sales-report-${moment(payload.from).unix()}`,'-')
      
      report.SetHead(this.setTitleHead())
      report.SetData(datatable)
      report.BuildData()
      window.location.reload()
    },
    setHeaderXlsx(){
     let head =["No","Payment Gateway","Total","Price IDR","Price USD","Price Point",
        "Gross Profit Margin","Gateway Fee","Gateway Cost","Balance"
      ]
      return head
    },
    setBodyXlsx(){
      let dataitems = []
      let n = 0;
      dataitems.push(this.setHeaderXlsx())
      this.items.forEach((e) => {
        let dataPeserta = [
          ++n,
          this.Payment(e.paymentgateway_id),
          e.total,
          e.currency_code == "IDR" ? parseInt(e.total_amount) : 0,
          e.currency_code == "USD" ? parseFloat(e.total_amount) : 0,
          e.currency_code == "PTS" ? parseInt(e.total_amount) : 0,
          this.formatPrice(e.total_amount, e.currency_code),
          this.FormatFormula(this.PaymentCost(e.paymentgateway_id)),
          this.formatPrice(this.CountAmountCost(e.total_amount,e.total,e.paymentgateway_id),e.currency_code),
          this.formatPrice(this.finalAmount(e.total_amount,this.CountAmountCost(e.total_amount,e.total,e.paymentgateway_id)),e.currency_code)
        ];
        dataitems.push(dataPeserta);
      });
      return dataitems
    },
    actionDownloadRecap(){
      if (this.items.length < 1) {
        this.$swal({
          toast: "true",
          position: "top-end",
          icon: "Gagal",
          title: "Tidak ada data yang di rekap",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {

        let payload = {
          from: moment(this.range.start).format("YYYY-MM-DD"),
          to: moment(this.range.end).format("YYYY-MM-DD"),
        };
        let title = payload.from + " - " + payload.to
        let newXlsx = new Xlsreport(title,payload,this.curs,title,"")
        let body = this.setBodyXlsx()
        newXlsx.setBody(body)
        newXlsx.BuildReport()
      }
    },
  },
};
</script>
<style scoped>
.books {
  font-family:"Avenir Next" ,"effra", Helvetica, Arial, sans-serif;
}
</style>
