var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-striped table-bordered table-sm table-responsive-sm mt-4"
  }, [_vm._m(0), !_vm.isLoading ? _c('tbody', [_vm._l(_vm.items, function (item, index) {
    return [_c('tr', {
      key: item.id
    }, [_c('td', [_vm._v(_vm._s(++index))]), _c('td', {
      staticStyle: {
        "width": "25%"
      }
    }, [_vm._v(_vm._s(_vm.Payment(item.paymentgateway_id)))]), _c('td', [_vm._v(_vm._s(item.total))]), item.currency_code == 'IDR' ? _c('td', {
      staticClass: "text-right"
    }, [_vm._v(" " + _vm._s(_vm.formatPrice(item.total_amount, item.currency_code)) + " ")]) : _c('td'), item.currency_code == 'USD' ? _c('td', {
      staticClass: "text-right",
      staticStyle: {
        "width": "30%"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatPrice(item.total_amount, item.currency_code)) + " ")]) : _c('td'), item.currency_code == 'PTS' ? _c('td', {
      staticClass: "text-right"
    }, [_vm._v(" " + _vm._s(_vm.formatPrice(item.total_amount, item.currency_code)) + " ")]) : _c('td'), _c('td', {
      staticClass: "text-right"
    }, [_vm._v(_vm._s(_vm.formatPrice(item.total_amount, item.currency_code)))]), _c('td', {
      staticClass: "text-right"
    }, [_vm._v(" " + _vm._s(_vm.FormatFormula(_vm.PaymentCost(item.paymentgateway_id))) + " ")]), _c('td', {
      staticClass: "text-right"
    }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.CountAmountCost(item.total_amount, item.total, item.paymentgateway_id), item.currency_code)) + " ")]), _c('td', {
      staticClass: "text-right"
    }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.finalAmount(item.total_amount, _vm.CountAmountCost(item.total_amount, item.total, item.paymentgateway_id)), item.currency_code)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.publisherNetshare(_vm.finalAmount(item.total_amount, _vm.CountAmountCost(item.total_amount, item.total, item.paymentgateway_id))), item.currency_code)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.applicationsFee(_vm.finalAmount(item.total_amount, _vm.CountAmountCost(item.total_amount, item.total, item.paymentgateway_id))), item.currency_code)) + " ")])])];
  })], 2) : _c('tbody', [[_c('tr', [_c('td', {
    attrs: {
      "colspan": "12"
    }
  }, [_c('div', {
    staticClass: "text-center text-danger my-2"
  }, [_c('b-spinner', {
    staticClass: "align-middle"
  }), _c('strong', [_vm._v(" Loading...")])], 1)])])]], 2), _c('tfoot', [_c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("Total")]), _c('td', [_vm._v(" " + _vm._s(_vm.formatPriceBasic(_vm.total_transactions)) + " ")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.totalAmount, "IDR")) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.totalAmountD, "USD")) + " ( " + _vm._s(_vm.formatPrice(_vm.totalAmountD * _vm.curs, "IDR")) + " ) ")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.totalAmountPTS, "IDR")) + " ")])]), _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "6"
    }
  }, [_vm._v("Total")]), _c('td', {
    staticClass: "text-right"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.SumAmount, "IDR")) + " ")])]), _c('td'), _c('td', {
    staticClass: "text-right"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.totalGatewayCost, "IDR")) + " ")])]), _c('td', {
    staticClass: "text-right"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.SumAmount - _vm.totalGatewayCost, "IDR")) + " ")])]), _c('td', {
    staticClass: "text-right"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.formatPrice((_vm.SumAmount - _vm.totalGatewayCost) * 0.7, "IDR")) + " ")])]), _c('td', {
    staticClass: "text-right"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.formatPrice((_vm.SumAmount - _vm.totalGatewayCost) * 0.3, "IDR")) + " ")])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Payment Gateway")]), _c('th', [_vm._v("Total")]), _c('th', [_vm._v("Price IDR")]), _c('th', [_vm._v("Price USD")]), _c('th', [_vm._v("Price Point")]), _c('th', [_vm._v("Gross Profit Margin")]), _c('th', [_vm._v("Gateway Fee")]), _c('th', [_vm._v(" Gateway Cost")]), _c('th', [_vm._v(" Balance")]), _c('th', [_vm._v("Publisher net Share ")]), _c('th', [_vm._v("Fee Aplication")])])]);
}]

export { render, staticRenderFns }